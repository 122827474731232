import { UserRole } from "./interfaces";

export const getUserRole = (user: any): UserRole | null => {
  if (!user) return null;

  if (user.type === "investor") {
    if (user.isAdmin) return UserRole.ADMIN;
    return UserRole.INVESTOR;
  }
  return null;
};
