import { cloneElement, FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { SideNavigationProps } from "./interfaces";
import cn from "classnames";
import SideNavLogo from "./SideNavLogo";
import { ReactComponent as AICopilotIcon } from "images/navigations/co-pilot.svg";
import { ReactComponent as SecondBrainIcon } from "images/navigations/second-brain.svg";
import { ChevronLeftIcon, ChevronRightIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline"
import { QueueListIcon } from "@heroicons/react/24/outline"

interface NavItemProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  end?: boolean;
  isSubItem?: boolean;
  isShrunk: boolean;
  className?: string;
  external?: boolean;
}

const SECOND_BRAIN_URL = "https://secondbrain.stage.sircular.io/";

const NavItem: FC<NavItemProps> = ({ to, icon, label, end = false, isSubItem = false, isShrunk, className, external = false }) => {
  const CommonContent = (
    <>
      <span className={cn("flex items-center justify-center", 
        isSubItem && isShrunk ? 'w-5 h-5' : 'w-7 h-7'
      )}>
        {cloneElement(icon as React.ReactElement, { 
          className: cn(
            isSubItem && isShrunk ? 'w-4 h-4' : isSubItem ? 'w-5 h-5' : 'w-7 h-7',
            to === "/aia" && isSubItem && 'w-5 h-5',
            isSubItem && 'text-gray-500'
          )
        })}
      </span>
      {!isShrunk && (
        <div className="flex items-center gap-x-1">
          <span className={cn(isSubItem && 'text-gray-500')}>{label}</span>
          {external && (
            <ArrowTopRightOnSquareIcon className="w-4 h-4 text-gray-500" />
          )}
        </div>
      )}
    </>
  );

  const commonClassName = cn(
    "flex items-center transition-colors duration-200",
    isSubItem ? "gap-x-2" : "gap-x-3",
    isSubItem && (isShrunk ? "pl-0" : "pl-6"),
    isShrunk ? "justify-center px-0" : "px-4",
    !isSubItem && "text-lg",
    isSubItem && "text-gray-500 py-1",
    !isSubItem && "py-2",
    isSubItem && isShrunk && "mt-1 mb-1",
    className
  );

  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={cn(commonClassName, "hover:bg-gray-100 hover:text-aqua-400")}>
        {CommonContent}
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      end={end}
      className={({ isActive }) =>
        cn(
          commonClassName,
          isActive ? "text-aqua-400 font-semibold" : "hover:bg-gray-100 hover:text-aqua-400"
        )
      }
    >
      {CommonContent}
    </NavLink>
  );
};

const SideNavigationDesktop: FC<SideNavigationProps> = ({
  config,
  user,
  userRole,
}) => {
  const [isShrunk, setIsShrunk] = useState(false);

  const logo = user?.logo?.large?.location || user?.image?.large?.location;
  const isAngelInvestor = user?.investorType === "ANGEL_INVESTOR";

  const tier = user?.tier;


  const toggleShrink = () => {
    setIsShrunk((prev) => !prev);
  };

  return (
    <nav
      className={cn(
        "group bg-white bottom-0 transition-all duration-500 flex flex-col border-r border-r-gray-200 shadow-sm h-screen",
        isShrunk ? "w-18" : "w-56"
      )}
    >
       
      <div className="p-4 flex items-center justify-between border-b border-gray-200 mb-4">
        <div className={cn("flex-grow flex justify-center", isShrunk ? "mr-2" : "mr-4")}>
          <span className={cn("text-black font-bold", isShrunk ? "text-sm" : "text-2xl")}>
            {isShrunk ? "" : "Sircular"}
          </span>
        </div>
        <button
          onClick={toggleShrink}
          className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200 flex-shrink-0"
        >
          {isShrunk ? <ChevronRightIcon className="w-5 h-5" /> : <ChevronLeftIcon className="w-5 h-5" />}
        </button>
      </div>

      <div className="h-6" />
      
      <div className="flex-grow overflow-y-auto">
        <NavItem
          to="/aia"
          icon={<AICopilotIcon />}
          label="Copilot"
          end
          isShrunk={isShrunk}
        />
        
        <NavItem
          to="/aia"
          icon={<ArrowUpTrayIcon />}
          label="Upload"
          isSubItem
          isShrunk={isShrunk}
        />
        
        <NavItem
          to="/dealflow"
          icon={<QueueListIcon />}
          label="Dealflow"
          isSubItem
          isShrunk={isShrunk}
        />

         <div className="h-8" />
        

        <NavItem
          to={SECOND_BRAIN_URL}
          icon={<SecondBrainIcon/>}
          label="Second Brain"
          isShrunk={isShrunk}
          external={true}
        />
      </div>

      {!isShrunk && (
        <div className="p-4 border-t border-gray-200">
          <SideNavLogo
            isAngelInvestor={isAngelInvestor}
            userRole={userRole}
            logo={logo}
            user={user}
            tier={tier}
          />
        </div>
      )}
    </nav>
  );
};

export default SideNavigationDesktop;
