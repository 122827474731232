import { FC } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Tier } from "gql/graphql";
import { UserRole } from "./interfaces";
import { setGlobalState } from "models/globalState/sagaActions";
import { logoutUser } from "models/auth/sagaActions";
import { useLang } from "core/lang/LangaugeSetting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkspaceSelector from "./WorkspaceSelector";
import cn from "classnames";

interface Props {
  userRole: UserRole;
  tier: Tier;
  isAngelInvestor: boolean;
  isOpen: boolean;
}

const SideNavMenu: FC<Props> = ({
  userRole,
  tier,
  isAngelInvestor,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const { lang } = useLang();

  const onHelpClicked = () => {
    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };

  const handleLogout = async () => {
    dispatch(logoutUser({ lang }));
    clearAllBodyScrollLocks();
  };

  const menuItemClassnName =
    "text-white opacity-90 flex items-center gap-2 py-2 text-sm font-bold cursor-pointer hover:text-aqua-400";

  return (
    <div
      className={cn(
        "absolute w-44 pl-8 py-2 right-0 bottom-16 z-10 mt-2 origin-top-right bg-[#202436] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition-all duration-300",
        isOpen ? "visible opacity-100" : "invisible opacity-0"
      )}
    >
      <WorkspaceSelector tier={tier} />

      {!isAngelInvestor && (
        <NavLink className={menuItemClassnName} to="/account/team-members">
          <span className="flex items-center justify-center w-4 h-4">
            <FontAwesomeIcon icon={["fal", "users"]} />
          </span>
          Manage team
        </NavLink>
      )}

      <NavLink className={menuItemClassnName} to="/account/api-docs">
        <span className="flex items-center justify-center w-4 h-4">
          <FontAwesomeIcon icon={["fal", "book"]} />
        </span>
        API Docs
      </NavLink>

      <NavLink
        className={menuItemClassnName}
        to={
          tier === Tier.Trial || tier === Tier.Pending
            ? "/plans"
            : "/account/billing"
        }
      >
        <span className="flex items-center justify-center w-4 h-4">
          <FontAwesomeIcon icon={["fal", "rocket-launch"]} />
        </span>
        {tier === Tier.Trial || tier === Tier.Pending ? "Upgrade" : "Billing"}
      </NavLink>

      <span className={menuItemClassnName} onClick={onHelpClicked}>
        <span className="flex items-center justify-center w-4 h-4">
          <FontAwesomeIcon icon={["fal", "question-circle"]} />
        </span>
        Help
      </span>

      <span className={menuItemClassnName} onClick={handleLogout}>
        <span className="flex items-center justify-center w-4 h-4">
          <FontAwesomeIcon icon={["fal", "sign-out"]} />
        </span>
        Logout
      </span>
    </div>
  );
};

export default SideNavMenu;
