import React from "react";

export interface SideNavigationProps {
  config: NavigationConfig;
  user: any;
  userRole: UserRole;
  className?: string;
  isNavigationEnabled?: boolean;
  onNavItemClicked?: () => void;
}

export enum UserRole {
  INVESTOR = "INVESTOR",
  ADMIN = "ADMIN",
}

export type NavLink = {
  id: string;
  title: string;
  to: string;
  Icon: React.FC<{ isActive?: boolean }>;
  elementId?: string;
  placement?: "top" | "bottom";
};

export type NavLinkWithChildren =
  | (NavLink & {
      children?: NavLink[];
    })
  | "DIVIDER";

export type NavigationConfig = {
  [key in UserRole]: NavLinkWithChildren[];
};

export interface NavigationItem {
  id: string;
  title: string;
  to?: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  elementId?: string;
  placement?: string;
  children?: NavigationItem[];
}
